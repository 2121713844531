import React from 'react';
import { BannerComponent } from '@src/interfaces';
import Banner from '@src/components/Banner/Banner';
import ParagraphProps from '@src/components/Paragraph/paragraph-props';

interface BannerParagraphData {
  banner: BannerComponent;
}

const BannerParagraph = ({ paragraph }: ParagraphProps) => {
  const data = paragraph.data as BannerParagraphData;
  return <Banner {...data.banner} />;
};

export default BannerParagraph;
